.navbar {
    display: flex;
    flex-direction: column;
    /* float: left; */
    background-color: rgb(3, 27, 78);
    position: relative;
    /* width: 30%; */
    min-height: 100%;
    min-width: 300px;
    /* padding: 10px 10px 10px 10px; */
    width: 30%;
}



.links {
    display: flex;
    flex-direction: column;
    /* gap: 10px; */
    padding-top: 20px;

}

.user-info {
    display: flex;
    justify-content: center;
    padding: 10px 10px 3% 10px;


}

.link {
    margin-right: 30px;
    margin-left: 30px;

    border-radius: 7px;

}

.links a {
    color: white;
    text-decoration: none;
    font-size: 20px;
    padding: 10px 10px 10px 10px;
    font-weight: bold;

    /*
        This is for the logo next to the link
    */
    gap: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}



.links a:visited {
    color: white;
    text-decoration: none;
}

.links a:hover {
    /* transform: scale(1.5); */
    background-color: rgb(76, 76, 136);
    cursor: pointer;

}

.loginButton {
    display: flex;
    margin: 10px;
}

@media screen and (width < 600px) {

    #close .navbar {
        display: none
    }

    #open .navbar {
        flex-direction: row;
        justify-content: center;
        width: 100%;
        position: absolute;
        z-index: 1;
        overflow: auto;
    }

    #open .links {
        flex-direction: column;
        justify-content: flex-start;
        width: 100%;
    }

}

.project-list {
    color: white;
}