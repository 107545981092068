body {
  margin: 0;
  font-family: 'Mukta', sans-serif;
  font-family: 'Source Code Pro', monospace;
  font-family: 'Ubuntu', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Mukta', sans-serif;
  font-family: 'Source Code Pro', monospace;
}