.profile-heading-info {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.profile-heading-text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}