.header {
    display: flex;
    justify-content: center;
    background-color: rgb(3, 27, 78);
    padding: 5px
}

.expand-button {
    padding: 0;
    border: none;
    background: none;
}

.expand-button:hover {
    transform: scale(1.2);
    cursor: pointer;
}


@media screen and (min-width: 600px) {
    .header {
        display: none;
    }
}