.edit-dropdown-label {
    display: flex;
    font-weight: bold;
}

.edit-dropdown-button-container {
    display: flex;
    gap: 10%;
    margin-top: 10px;
}

.edit-dropdown {
    min-width: 250px;
}