.edit-with-modal {
    display: flex;
    justify-content: space-between;
    width: 75%;

}

.profile-settings-button {
    max-width: 0%;
    order: 3;
}

.profile-setting-label-and-edit-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 50%;

}

.profile-setting-label {
    font-size: 1.0rem;
    font-weight: bold;
}