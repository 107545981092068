.about-container {
  margin: auto;
  border-radius: 5px;
  width: 90%;
  border: 1px solid #e0e0e0;
  margin-top: 30px;
  /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
}

.title {
  text-align: justify;
  padding: 10px 20px;
  /* outline: white; */
  border-radius: 2px;
  margin-top: 0;
  color: white;
  background-color: rgb(3, 27, 78);
}

.about-info {
  text-align: justify;
  padding: 0 20px;
}

.owner-logo-1 {
  margin-left: 10px;
  border-radius: 50%;
  height: 100px;
  width: 100px;
  background: url("./abdullah-myopenlib.png") no-repeat center/contain;
  background-size: contain;
}

/* .owner-logo-2 {
  margin-right: 20px;
  border-radius: 50%;
  height: 100px;
  width: 100px;
  background: url("./harris-myopenlib.jpg") no-repeat center/contain;
  background-size: contain;
} */
.developers {
  display: flex;
}