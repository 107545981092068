.custom-button {
    background-color: rgb(3, 27, 78);

    border: none;
    color: white;
    text-decoration: none;
    font-size: 15px;
    box-shadow: none;
    border-radius: 0px;
    padding: 10px 10px 10px 10px;
    font-weight: bold;

}

.custom-button:hover {
    background-color: rgb(76, 76, 136);
    cursor: pointer;
}

.custom-button-container:hover {
    background-color: rgb(76, 76, 136);
    cursor: pointer;
    margin-right: 16px;
    margin-left: 16px;

    border-radius: 7px;
}