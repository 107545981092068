.notifications-dropdown {
    background-color: white;
    position: absolute;
    border-radius: 5px;
    padding: 10px;
    /* min-width: 150px; */
    border: solid 2px black;
    z-index: 100;
    min-width: 300px;
    overflow-y: scroll;
    max-height: 500px;
}

@media (max-width: 599px) {
    .notifications-dropdown {
        right: 0;

    }
}



.notification {
    /* min-height: 50px; */
    text-align: left;
    display: flex;
    align-items: center;
    gap: 10px;

    /* border-bottom: solid 1px black; */
}

.notification:hover {
    background-color: #f5f5f5;
    cursor: pointer;
}

.notification-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
}

.mark-all-as-unread {
    color: #007bff;
    cursor: pointer;
}

.mark-all-as-unread:hover {
    text-decoration: underline;
}