.form {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 1rem;
    z-index: 10;
}

.child-input-container {
    width: 88%;
    max-width: 100%;
}

.form .input-container {
    width: 350px;
    max-width: 100%;
    border: 1px solid #dfe1e5;
    display: flex;
    align-items: center;
    height: 44px;
}

.form .input-container .input {
    color: rgba(0, 0, 0, 0.87);
    outline: none;
    flex: 1;
    border: none;
    width: 100%;
}

.form .input-container .input.h-full {
    height: 35px;
    margin-left: 8px;
    margin-right: 8px;
}

.form .input-container .input.h-min {
    height: 27px;
    margin-left: 8px;
    margin-right: 8px;
}

.form .input-container.def {
    border-radius: 4px;
}

.form .input-container .input::placeholder {
    text-transform: capitalize;
}

.container-searchList {
    background-color: white;
    width: 350px;
    overflow-y: scroll;
    height: 300px;
    margin-top: 10px;
    border: 1px solid #dfe1e5;
    max-width: 100%;
    border-radius: 4px;
    padding: 15px;
}

#modal::-webkit-scrollbar {
    width: 8px;
}

/* Track */
#modal::-webkit-scrollbar-track {
    border-radius: 10px;
}

/* Handle */
#modal::-webkit-scrollbar-thumb {
    background: #3e4042b6;
    border-radius: 10px;
}

/* Handle on hover */
#modal::-webkit-scrollbar-thumb:hover {
    background: rgba(62, 69, 69, 0.775);
}

.content-image-searchList {
    height: 44px;
    border-radius: 50%;
    object-fit: contain;
}

.flex {
    display: flex;
    align-items: center;
}

.content-searchList:hover {
    background-color: #eee;
}

.content-searchList:nth-child(1) {
    margin-top: 0px;
}

.content-searchList {
    margin-top: 10px;
    display: flex;
    padding: 8px;
    border-radius: 5px;
    cursor: pointer;
    max-width: 100%;
    align-items: center;
    justify-content: space-between;
}

.mleft {
    margin-left: 8px;
    color: #202124;
}

.close-btn {
    background: none;
    border: none;
    color: #777;
    cursor: pointer;
    font-size: 22px;
    line-height: 15px;
    opacity: 0.6;
    height: 30px;
    width: 30px;
}

@media (max-width: 375px) {
    .input.h-full {
        margin-right: 0px !important;
    }
}