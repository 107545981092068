.App-container {
  text-align: center;
  display: flex;
  background-color: rgb(255, 255, 255);
  min-height: 100%;

  width: 100%;
  position: absolute;
}

.routes {
  width: 100%;
}

@media screen and (max-width: 600px) {
  #open .routes {
    margin-top: 300px;
  }
}

.page {
  margin-top: 20px;
}

.custom-table-container {
  padding: 20px 20px 20px 20px;

}